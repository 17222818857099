import React from 'react';
import { Card, Divider, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as COLORS from "@mui/material/colors";
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import {QuoteProps, QuotesSectionProps} from "./_LandingPageInterfaces";


// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const useStyles = makeStyles((theme: Theme) => ({
    quotesContainer: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(3),
        //backgroundColor: COLORS.grey[50]
    },
    quotesGrid: {
        marginTop: theme.spacing(1),
    },
    quotesCard: {
        padding: theme.spacing(2),
        height: "100%"
    },
    quotesTitle: {
        textAlign: "center",
    },
    quotesTextBody: {
        padding: 0,
    },
    quotesTextName: {
        marginBottom: -theme.spacing(1),
    },
    quotesTextCaption: {
        fontSize: "0.85rem",
        padding: 0,
        paddingTop: theme.spacing(1)
    },
    quotesDivider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    quotesIcon: {
        //opacity: "20%",   TODO Fix issues with opacity
        position: "absolute",
        marginLeft: "-15px",
        marginTop: "-20px",
        width: "60px",
        height: "60px"
    },
    quotesContentGrid: {
        height: "100%"
    }
}));

function Quote({name, caption, body}: QuoteProps) {
    // Displays a quote
    //
    //      name: string - The name of the user who said it
    //
    //      caption: string - A caption, typically their job title
    //
    //      body: string - The actual quote

    const classes = useStyles();

    return (
        <Card elevation={3} className={classes.quotesCard}>
            {/*<FormatQuoteRoundedIcon className={classes.quotesIcon}/>*/}
            <Grid container direction="column" justifyContent="space-between" className={classes.quotesContentGrid}>
                <Grid item>
                    <Typography variant="body2" className={classes.quotesTextBody}>
                        {body}
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider className={classes.quotesDivider}/>
                    <Typography variant="h5" className={classes.quotesTextName}>
                        {name}
                    </Typography>
                    <Typography variant="body2" className={classes.quotesTextCaption}>
                        {caption}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
}

export function QuotesSection({quotes}: QuotesSectionProps) {
    // This displays a list of quotes from different users.
    //
    //      quotes: QuoteProps[] - A list of quotes to be displayed

    const classes = useStyles();

    return (
        <div className={classes.quotesContainer}>
            <Typography variant="h3" className={classes.quotesTitle}>
                What our Users say about us
            </Typography>
            <Grid container spacing={4} direction="row" justifyContent="space-evenly" alignItems="stretch" className={classes.quotesGrid}>
                {quotes.map((quote) => {
                    return (
                        <Grid item xs={12} sm={4}>
                            <Quote name={quote.name} caption={quote.caption} body={quote.body}/>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );
}
