import React from 'react';
import { Grid, Typography, Avatar } from '@mui/material';

const MeetOurTeam: React.FC = () => {
  return (
    <Grid
  container
  spacing={4}
  justifyContent="center"
  alignItems="center"
  style={{ maxWidth: '900px', margin: '0 auto' }} // Restricting the width and centering
>
  <Typography variant="h4" gutterBottom style={{ textAlign: 'center', width: '100%', marginBottom: '0.1em' }}>
    Meet Our Team
  </Typography>

  {/* Team Members */}
  {[
    { name: 'Mark Robinson', title: 'Founder, Software Developer', image: '/img/profile_pics/mark.jpg' },
    { name: 'Louis Davidson', title: 'Software Developer', image: '/img/profile_pics/louis.jpg' },
    { name: 'Aryaa Saravanakumar', title: 'Software Developer', image: '/img/profile_pics/aryaa.jpg' },
    { name: 'Chloe Weatherhead', title: 'Communications', image: '/img/profile_pics/chloe2.jpg' },
    { name: 'Sally Abbatt', title: 'Strategic Advisor', image: '/img/profile_pics/sally.jpg' },
    { name: 'Aidan Hobson Sayers', title: 'Technical Advisor', image: '/img/profile_pics/aidan.jpg' },
  ].map((member, index) => (
    <Grid item xs={12} sm={6} md={4} key={index} style={{ textAlign: 'center' }}>
      <Avatar
        alt={member.name}
        src={member.image}
        style={{ margin: '0 auto', width: '175px', height: '175px' }} // Set avatar size here
      />
      <Typography variant="h6" style={{ textAlign: 'center' }}>{member.name}</Typography>
      <Typography variant="body2" style={{ textAlign: 'center' }}>{member.title}</Typography>
    </Grid>
  ))}
</Grid>

  );
};

export default MeetOurTeam;
