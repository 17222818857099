import React from 'react';
import { Link, withRouter} from 'react-router-dom';
import { Button, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as IMAGES from '../../constants/images';
import {PageChangeFunctions, User} from "../../my_interfaces";
import {HeroSectionProps} from "./_LandingPageInterfaces";

// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(3),
    },
    containerItem: {
        maxWidth: "100%",
        [theme.breakpoints.down('md')]: {
            margin: "auto",
        }
    },
    contentContainer: {
        margin: "auto",
    },
    title: {
        color: "#202124",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            textAlign: "center",
        }
    },
    buttonContainer: {
        [theme.breakpoints.down('md')]: {
            margin: "auto",
        }
    },
    button: {
        ...theme.typography.h6,
        fontSize: 16,
        letterSpacing: 0.8,
        textTransform: "none",
    },
    image:{
        maxWidth: "100%",
        margin: "auto"
    },
}));


function HeroSection({user, handleLogout,}: HeroSectionProps) {
    // We store all the CSS classes in the variable `classes`
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Grid container direction="row" spacing={5}>
                <Grid item xs={12} md={5} className={classes.contentContainer}>
                    <Grid container direction="column">
                        <Grid item xs={8} className={classes.containerItem}>
                            <Typography variant="h3" component="h2" className={classes.title}>
                                {/* REF_91876 */}
                                Improve student <b>understanding</b>, <br/> with cutting-edge AI
                            </Typography>
                        </Grid>
                        <Grid item className={classes.containerItem}>
                            {
                                !user ? (
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item className={classes.buttonContainer}>
                                            <Button variant="outlined" color="primary" size="large"
                                                    className={classes.button} component={Link} to='/login'>
                                                Log In
                                            </Button>
                                        </Grid>
                                        <Grid item className={classes.buttonContainer}>
                                            <Button variant="contained" color="primary" size="large"
                                                    className={classes.button} component={Link} to='/register'>
                                                Get Started
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item className={classes.buttonContainer}>
                                            <Button variant="outlined" color="primary" size="large"
                                                    className={classes.button} onClick={handleLogout}>
                                                Log Out
                                            </Button>
                                        </Grid>
                                        <Grid item className={classes.buttonContainer}>
                                            <Button variant="contained" color="primary" size="large"
                                                    className={classes.button} component={Link} to='/myaccount'>
                                                My Account
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12} md={7}>
                    <img src={IMAGES.HERO_IMAGE} className={classes.image}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default HeroSection;
