import React from 'react';
import { Grid, Typography, Paper, Avatar } from '@mui/material';
import MeetOurTeam from './MeetOurTeam';
import MeetOurSupporters from './MeetOurSupporters';

const AboutPage: React.FC = () => {
  // YouTube video options, change the video ID if you find one
  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  // Replace this with a relevant video URL from YouTube
  const videoId = 'dQw4w9WgXcQ'; // Placeholder video ID

  return (
    <div className = "center">
    <Grid container spacing={4} justifyContent="center">
      {/* Story Section */}
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
          About MyMarkingMachine
        </Typography>
          <Typography variant="body1" gutterBottom>
            Mark Robinson, a passionate physics teacher and former tutor, founded MyMarkingMachine.com to address the inequalities in educational opportunities. 
            The platform was created to provide personalized, AI-driven feedback to students, helping them reach their full potential, particularly in subjects like physics. 
            Mark’s vision is to expand the platform to cover other subjects like biology and chemistry, ensuring that all students, regardless of their background, have access 
            to high-quality education.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Unlike traditional methods, MyMarkingMachine allows students to receive instant feedback, guiding them to the right answers without giving the solution directly. 
            This AI-powered system helps teachers reduce their workload while enhancing students’ learning experiences. 
            Mark's mission is to provide these tools to schools in need, leveling the playing field in education.
          </Typography>
        </Paper>
      </Grid>

           {/* Team Section */}
      <MeetOurTeam />

       {/* Embedded Video */}
       {/* <Grid item xs={12} style={{ textAlign: 'center' }}> */}
        {/* <Typography variant="h5" gutterBottom> */}
          {/* Watch Our Story */}
        {/* </Typography> */}
        {/* <YouTube videoId={videoId} opts={videoOptions} /> */}
      {/* </Grid> */}
      <MeetOurSupporters/>

    </Grid>
    </div>
  );
};

export default AboutPage;
