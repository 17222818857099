import React from 'react';
import { Link, withRouter} from 'react-router-dom';
import {
    AppBar,
    Button,
    IconButton,
    Theme,
    Toolbar,
    Typography,
    Drawer,
    ListItem,
    ListItemText,
    Divider,
    ListItemIcon,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as COLORS from "@mui/material/colors";
import MenuIcon from '@mui/icons-material/Menu';
import LockIcon from '@mui/icons-material/Lock';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BugReportIcon from '@mui/icons-material/BugReport';
import HelpIcon from '@mui/icons-material/Help';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import {NavigationProps, DrawerButtonProps} from "./_NavigationInterfaces";
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';


// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const theme = createTheme();
const useStyles = makeStyles((theme: Theme) => ({
    headerBar: {
        // Applied to the Navigation bar, use this for color changes, margins or padding
        backgroundColor: COLORS.grey[50],
        [theme.breakpoints.down(600)]: {
            position: "initial",
        }
    },
    headerTitleTextContainer: {
        // Holds the page title, this is a flex-box item that expands to fill the gap
        marginLeft: theme.spacing(1),
        minWidth: "fit-content",
        flexGrow: 1,
    },
    headerTitleText: {
        // Applied to the page title text, disappears when the page gets too small
        cursor: "pointer",
        letterSpacing: "0em",
        textDecoration: 'none',
        color: 'black',
        [theme.breakpoints.down(600)]: {
            display: "none", 
        },
    },
    headerButton: {
        // Login/Get Started buttons on top right of screen, they use the same text as h6 with a few changes
        ...theme.typography.h6,
        fontSize: 16,
        letterSpacing: 0.8,
        textTransform: "none",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down(400)]: {
            display: "none",
        }
    },
    drawerOpenButton: {
        // Icon on top left that opens the drawer
        padding: 0,
        width: "35px",
        height: "35px"
    },
    drawerButton: {
        // List buttons in the drawer, minWidth makes sure the drawer is a good size
        minWidth: "200px"
    },
    navBarLinkButton: {
        ...theme.typography.h6,
        fontSize: 16,
        letterSpacing: 0.8,
        textTransform: "none",
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    }
}));

function DrawerButton({ title, enabled, disableDrawer, handleLogout, icon, to }: DrawerButtonProps) {
    // REF_12077
    // These buttons are displayed on the NavDrawer and allow the user to switch to different pages.
    // They can either have an icon provided by MaterialUI, or they can just have the text.
    //
    //      title: string - The text on the button
    //
    //      enabled: boolean - Whether or not to display this button, normally provided by NavDrawer.isShown
    //
    //      onClick: () => void - The function to call when clicked, normally the function to change to the correct page
    //
    //      disableDrawer: () => void - The function to call to close the drawer when the button is clicked
    //
    //      icon?: React.ReactNode - The icon to display next to the text, this is optional
    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(handleLogout){
            handleLogout(); // Execute onClick if provided
        } 
        disableDrawer();      // Always close the drawer after action
    };

    // Render the ListItem as a Link if 'to' is provided, otherwise (for login and logout) just render the ListItem. For some reason couldn't use component={Link} inside <ListItem>
    if (!enabled) return null;

    return (
        <ListItem button key={title} onClick={handleClick} className={classes.drawerButton}>
            {to ? (
                <Link to={to} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', width: '100%' }}> 
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={title} />
                </Link>
            ) : (
                <>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={title} />
                </>
            )}
        </ListItem>
    );
}

function NavDrawer({user, handleLogout, history, location, match}: NavigationProps) {
    // This component controls and displays the side-drawer for navigation, if you want to add new buttons
    // to the navigation drawer, you can do that by adding a new DrawerButton in the return statement.
    // Refer to this folder's README.md for more details.
    //
    //      user: User - Holds the current logged in user, passed down from App2 TODO Replace with React Context
    //
    //      buttonOnClicks: PageChangeFunction - Object with all the functions for changing the current page TODO Replace with React Context or React Router

    // This allows me to use the CSS classes defined at the top of the file with this component
    const classes = useStyles();

    // Holds a boolean value of whether or not to display the side-drawer
    const [open, setOpen] = React.useState(false);

    const isShown = (superuser: boolean, teacher: boolean, pupil: boolean, qcreator: boolean, none: boolean) => {
        // REF_87208
        // This determines whether a button should be displayed or not,
        // the function is provided with booleans that tell it whether or not to display based
        // on the `User.usertype`, e.g. if you wanted a button to only display when a pupil was logged in,
        // you would use `isShown(false, false, false true)

        if (user) {
            if (user.usertype === "superuser" && superuser) { return true }
            if (user.usertype === "teacher" && teacher) { return true }
            if (user.usertype === "pupil" && pupil) { return true }
            if (user.usertype === "qcreator" && qcreator) { return true }
        } else {
            if (none) { return true }
        }

        return false
    };

    return (
        <div>
            <IconButton
                onClick={() => setOpen(true)}
                className={classes.drawerOpenButton}
                size="large">
                <MenuIcon className={classes.drawerOpenButton}/>
            </IconButton>
            <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
                {/* Here you can add new links into the Drawer REF_26782*/}
                <DrawerButton
                    title="Home"
                    enabled={isShown(true,true, true, true, true)}
                    disableDrawer={() => setOpen(false)}
                    icon={<HomeIcon/>}
                    to={'/'}
                />
                <DrawerButton
                    title="Exercises"
                    enabled={isShown(true,true, true, true, false)}
                    disableDrawer={() => setOpen(false)}
                    icon={<LibraryBooksIcon/>}
                    to={'/exercises'} //TODO this should be a parameter passed in from above as PageNames[]
                />
                <DrawerButton
                    title="Teacher Home"
                    enabled={isShown(true,true, false, true, false)}
                    disableDrawer={() => setOpen(false)}
                    icon={<PeopleIcon/>}
                    to={'/teacherhome'}
                />
                <DrawerButton
                    title="Reports"
                    enabled={isShown(true,false, false, true, false)}
                    disableDrawer={() => setOpen(false)}
                    icon={<BugReportIcon/>}
                    to={'/reports'}
                />
                <DrawerButton
                    title="Test"
                    enabled={isShown(true,false, false, true, false)}
                    disableDrawer={() => setOpen(false)}
                    icon={<HelpIcon/>}
                    to={'/test'}
                />
                <DrawerButton
                    title="Users"
                    enabled={isShown(true,false, false, true, false)}
                    disableDrawer={() => setOpen(false)}
                    icon={<SupervisorAccountIcon/>}
                    to={'/users'}
                />
                <DrawerButton
                    title="About"
                    enabled={isShown(true,true, true, true, true)}
                    disableDrawer={() => setOpen(false)}
                    icon={<InfoIcon/>}
                    to={'/about'}
                />
                {/* <DrawerButton
                    title="Blog"
                    enabled={isShown(true,true, true, true, true)}
                    disableDrawer={() => setOpen(false)}
                    icon={<ArticleIcon/>}
                    to={'/blog'}
                /> */}
                <Divider/>
                <DrawerButton
                    title="Log In"
                    enabled={isShown(false,false, false, true, true)}
                    disableDrawer={() => setOpen(false)}
                    icon={<LockIcon/>}
                    to={'/login'}
                />
                <DrawerButton
                    title="Sign Up"
                    enabled={isShown(false,false, false, true, true)}
                    disableDrawer={() => setOpen(false)}
                    icon={<AssignmentIndIcon/>}
                    to={'/register'}
                />
                <DrawerButton
                    title="My Account"
                    enabled={isShown(true,true, true, true, false)}
                    disableDrawer={() => setOpen(false)}
                    icon={<AccountCircleIcon/>}
                    to={'/myaccount'}
                />
                <DrawerButton
                    title="Log Out"
                    enabled={isShown(true, true, true, true, false)}
                    disableDrawer={() => setOpen(false)}
                    handleLogout={handleLogout}
                    icon={<ExitToAppIcon/>}
                />
            </Drawer>
        </div>
    );
}


function Navigation({user, handleLogout, history, location, match}: NavigationProps) {
    // We store all the CSS classes in the variable `classes`
    const classes = useStyles();

    let guaranteed_userType;
    if (!user) {
        guaranteed_userType = "";
    } else {
        guaranteed_userType = user.usertype
    }

    return (
        
        <AppBar position="sticky" sx={{backgroundColor:"whitesmoke"}} className={classes.headerBar}>
            <Toolbar>
                <NavDrawer user={user} handleLogout={handleLogout} history={history} location={location} match={match}/>
                <div className={classes.headerTitleTextContainer}>
                    <Typography variant="h5" component={Link} to="/" className={classes.headerTitleText}>
                        My Marking Machine
                    </Typography>
                </div>
                {
                    guaranteed_userType == "pupil" ? (
                        <div>
                            <Button color="primary" size="large" component={Link} to="/exercises" className={classes.navBarLinkButton}>
                                Exercises
                            </Button>
                        </div>
                    ) : (
                        guaranteed_userType == "teacher"||guaranteed_userType == "superuser" ? (
                            <div>
                                <Button color="primary" size="large" component={Link} to="/exercises" className={classes.navBarLinkButton}>
                                    Exercises
                                </Button>
                                <Button color="primary" size="large" component={Link} to="/teacherhome" className={classes.navBarLinkButton}>
                                    Teacher Home
                                </Button>
                            </div>
                        ) : (<div/>)
                    )
                }
                {
                    !user ? (
                        <div>
                            <Button variant="outlined" color="primary" size="large" component={Link} to="/login" className={classes.headerButton}>
                                Log In
                            </Button>
                            <Button variant="contained" color="primary" size="large" component={Link} to="/register" className={classes.headerButton}>
                                Get Started
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button variant="outlined" color="primary" size="large" onClick={handleLogout} className={classes.headerButton}>
                                Log Out
                            </Button>
                            <Button variant="contained" color="primary" size="large" component={Link} to="/myaccount" className={classes.headerButton}>
                                My Account
                            </Button>
                        </div>
                    )
                }

            </Toolbar>
        </AppBar>
        
    )
}


export default withRouter(Navigation);