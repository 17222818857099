import { Typography } from '@mui/material';
import React from 'react';

const supporters = [
  { name: 'SHINE Trust', logo: '/img/lts-logo.png', link: 'https://shinetrust.org.uk/' },
  { name: 'Ogden Trust', logo: '/img/ogden_trust_logo.png', link: 'https://www.ogdentrust.com/' },
  //{ name: 'Company C', logo: '/path/to/logo3.png', link: 'https://companyC.com' },
  // Add more supporters as needed
];

const Supporters: React.FC = () => {
    return (
      <div style={styles.container}>
        <Typography variant="h4" gutterBottom style={{ textAlign: 'center', width: '100%', marginBottom: '0.7em' }}>
    Our Supporters
  </Typography>
        <div style={styles.logoContainer}>
          {supporters.map((supporter, index) => (
            <a key={index} href={supporter.link} target="_blank" rel="noopener noreferrer">
              <img
                src={supporter.logo}
                alt={`${supporter.name} logo`}
                style={styles.logo}
              />
            </a>
          ))}
        </div>
      </div>
    );
  };
  
  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      textAlign: 'center',
      padding: '20px',
    //  backgroundColor: '#f9f9f9',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '7em',
    },
    logo: {
      height: '7em',
      width: 'auto',
      transition: 'transform 0.3s ease',
    },
  };
  
  export default Supporters;
  