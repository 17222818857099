import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button, Box } from '@mui/material';

const BlogPage: React.FC = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // Add logic to handle form submission here (e.g., send the email to your server or API)
    console.log(`Newsletter signup: ${email}`);
  };

  return (
    
    <Grid container spacing={4} justifyContent="center">
      {/* Blog Section */}
      <div className = "center">

        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
              Ghost Marking, what is it and why should we do it?
            </Typography>
            <Typography variant="body1" gutterBottom>
              We've all seen it: kids get their test papers back, and the first thing they do is glance at their score. This emotional reaction often overshadows the opportunity for real learning...
            </Typography>
            <Typography variant="body1" gutterBottom>
              [Continue the blog post here, focusing on sections like the emotional rollercoaster of scores, the science behind formative feedback, and why teachers are rethinking feedback.]
            </Typography>
          </Paper>
        </Grid>
      </div>

      {/* //Newsletter Signup Form
      <div className = "center">
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: '20px' }}>
          <Typography variant="h5" gutterBottom>
            Stay up to date
          </Typography>
          <Typography variant="body1" gutterBottom>
            Send us an email so we can keep you updated with the latest developments at MyMarkingMachine, educational insites and news in AI and education. Sign up now!
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <TextField
              label="Your Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              style={{ marginRight: '10px' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ height: '56px' }}
            >
              Subscribe
            </Button>
          </Box>
        </Paper>
      </Grid>
      </div> */}
    </Grid>
  );
};

export default BlogPage;
